import {Injectable} from "@angular/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ApiService} from "./api.service";
import {
  BehaviorSubject,
  distinctUntilChanged,
  Observable,
} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  private lastUpdated: number = 0;
  private settingsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public settings$: Observable<any> = this.settingsSubject
    .asObservable()
    .pipe(
      takeUntilDestroyed(),
      distinctUntilChanged()
    );

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean> = this.isLoadingSubject
    .asObservable()
    .pipe(
      takeUntilDestroyed(),
      distinctUntilChanged()
    );

  constructor(
    private apiService: ApiService,
  ) {
    this.refresh();
  }

  public refresh(): void {
    if (this.isLoading && ! this.isSettingsExpired()) {
      return;
    }

    this.isLoadingSubject.next(true);

    this.apiService.get('/app/app_settings')
      .pipe(
        map((res: any) => {
          this.settingsSubject.next(res);
          this.isLoadingSubject.next(false);

          this.lastUpdated = Math.floor(Date.now() / 1000); // ms to sec

          return res;
        })
      ).subscribe();
  }

  public get isLoading(): boolean {
    return this.isLoadingSubject.getValue();
  }

  public get settings() {
    return this.settingsSubject.getValue();
  }

  public getSettings(): Observable<any> {
    if ( ! this.settings || ! this.settings.length) {
      this.refresh();
    }

    return this.settings$;
  }

  public getIsBarionEnabled(): boolean {
    const settings = this.settingsSubject.getValue();

    return (
      (settings.payment_barion_pg_status === '1' || settings.payment_barion_pg_status === true) &&
      (settings.payment_pg_status === '1' || settings.payment_pg_status === true)
    );
  }

  public getIsAppleIapEnabled(): boolean {
    const settings = this.settingsSubject.getValue();

    return (
      (settings.payment_apple_iap_pg_status === '1' || settings.payment_apple_iap_pg_status === true) &&
      (settings.payment_pg_status === '1' || settings.payment_pg_status === true)
    );
  }

  public getIsPaymentEnabled(): boolean {
    const settings = this.settingsSubject.getValue();

    return settings.payment_pg_status === '1' || settings.payment_pg_status === true;
  }

  private isSettingsExpired(): boolean {
    // last updated + 1 hr
    const validUntil = this.lastUpdated + 3600;
    const now = Math.floor(Date.now() / 1000);

    return now <= validUntil;
  }

}
